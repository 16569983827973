import {useState} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import {convertMDContent, media, sendForm} from '../../../utils'
import TextInput from '../../inputs/TextInput'
import SubmitButton from '../../inputs/SubmitButton'
import Typography from '../../atoms/Typography'


const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7.5rem 23rem;
  text-align: center;

  ${media.down('mobile')(css`
    padding: 3.75rem 1.5rem 5rem;
  `)}
`

const StyledTitle = styled(Typography)`
  padding-bottom: 3.125rem;
`

const StyledMessage = styled.span`
  opacity: 0.5;
  padding-bottom: 2.5rem;
  line-height: 3.75rem;
`

const StyledForm = styled.form`
  display: flex;
  position: relative;
  flex-direction: row;
  padding-bottom: 2.5rem;
  width: 100%;
`

const StyledInput = styled(TextInput)`
  border-right: 0;
  border-radius: 3px 0 0 3px;
  width: 70%;

  &:hover,
  &:focus-within {
    border-right: 0;
  }
`

const StyledButton = styled(SubmitButton)`
  position: absolute;
  right: 0;
  width: 31%;
`

const StyledText = styled(Typography)`
  p {
    line-height: 2;
    color: ${({theme}) => `${theme.colors.secondary}50`};
    font-size: 0.875rem;

    a {
      color: ${({theme}) => `${theme.colors.secondary}50`};
    }
  }
`

const Newsletter = ({title, writeUsText, settings, ...props}) => {
  const initialData = {email: ''}
  const [formData, setFormData] = useState(initialData)
  const [showMessage, setShowMessage] = useState(false)

  const handleChange = (name, value) => setFormData((prevValues) => ({...prevValues, [name]: value}))

  const handleSubmit = async (e) => {
    e.preventDefault()

    await sendForm({'form-name': 'newsletter', ...formData})
      .then(() => setShowMessage(true))
      .catch((error) => console.error(error))
  }

  return (
    <StyledContainer {...props}>
      <StyledTitle variant="h2" type="h6">{title}</StyledTitle>
      <Choose>
        <When condition={showMessage}>
          <StyledMessage>{settings.successMessage}</StyledMessage>
        </When>
        <Otherwise>
          <StyledForm
              name="newsletter"
              method="post"
              action="/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="newsletter" />
            <StyledInput
                type="email"
                name="email"
                label={settings.email}
                value={formData?.email}
                onChange={handleChange}
                required
            />
            <StyledButton value={settings.confirmButton} />
          </StyledForm>
        </Otherwise>
      </Choose>
      <StyledText type="div" dangerouslySetInnerHTML={{__html: convertMDContent(writeUsText)}} />
    </StyledContainer>
  )
}

Newsletter.propTypes = {
  title: PropTypes.string,
  settings: PropTypes.object,
  writeUsText: PropTypes.string,
}

export default Newsletter
